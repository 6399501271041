.neomp-site-wrapper {
    .mat-progress-bar-fill::after {
         background-color: #00254D;
     }

    .mat-progress-bar-buffer {
        background: $msg-color-secondary-special;
    }

    .mat-progress-bar {
        background: $msg-color-secondary-special;
        position: fixed;
        z-index: 10000;
    }
    .mat-progress-bar-background {
        background: $msg-color-secondary-special;
     }
}
