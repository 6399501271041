.neomp-site-wrapper,
.cdk-overlay-container {
  .mat-raised-button {
    border-radius: 4px;
    line-height: $mat-button-line-height;
    padding: 0 $mat-button-padding;

    &.mat-secondary {
      background-color: #70c9e6;

      .mat-button-wrapper {
        color: $msg-button-primary-color;
        text-transform: $msg-button-text-transform;
        font-weight: $msg-button-font-weight;
      }
      &:hover {
        background-color: #b0ebff;
      }
    }

    &.mat-primary {
      background-color: #00254d;
      .mat-button-wrapper {
        color: $msg-button-primary-color;
        text-transform: $msg-button-text-transform;
        font-weight: $msg-button-font-weight;
      }
      &:hover {
        background-color: #1d426e;
      }
      span {
        color: #FFF;
      }
    }
    &.mat-warn {
      background-color: #e3cccc;
      .mat-button-wrapper {
        color: #710206;
        text-transform: $msg-button-text-transform;
        font-weight: 900;
      }
      &:hover {
        background-color: rgb(192, 124, 124);
      }
    }
    &.mat-lead {
      background-color: #bfc8d2;
      .mat-button-wrapper {
        color: #02254d;
        text-transform: $msg-button-text-transform;
        font-weight: 900;
      }
      &:hover {
        background-color: #a7bcd4;
        color: white;
      }
    }
  }

  .mat-stroked-button {
    color: #00254d;
    border-radius: 4px;

    &.mat-primary {
      background-color: #00254d;
      .mat-button-wrapper {
        color: $msg-button-primary-color;
        font-weight: $msg-button-font-weight;
      }
      &:hover {
        background-color: #1d426e;
      }
      span {
        color: #00254d;
      }
    }
  
  }
  .neomp-button-bar {
    display: flex;
    justify-content: flex-end;

    .neomp-button-bar-buttons {
      margin: 5px;
    }
  }
}
.button-right-bottom {
  position: absolute;
  right: 6px;
  bottom: -16px;
}
.mat-raised-button[disabled] {
  background-color: transparent !important;
  border: 1px solid #bfc8d2;
  border-radius: 4px;
}
.mat-raised-button[disabled] .mat-button-wrapper {
  color: #bfc8d2 !important;
}
.mat-raised-button.gm-offer-button {
  background-color: #eb6a31;
  color: white;
  span {
  color: white;
  }
}