@import "msg.mp.partial.colors";

@mixin table-border-bottom {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: $msg-color-secondary-special;
}

@mixin table-filter-column {
    padding-right: unset;
    width: $mat-table-icon-column-width;
    height: $mat-table-row-height;
}
@mixin table-column-filter-element {
    font-size: $msg-font-size-icons;
    color: $msg-color-bg;
    text-align: center;
}

// Docu: http://aslanbakan.com/en/blog/browser-and-device-specific-css-styles-with-sass-and-less-mixins/
@mixin browser($browsers: Edge) {
    @each $browser in $browsers {
        html[data-browser*="#{$browser}"] & {
            @content;
        }
    }
}