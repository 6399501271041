
.neomp-site-wrapper {
.mat-radio-outer-circle{
    border-color:#00254D;
}
 .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #00254D;
    }
    .mat-radio-button.mat-accent .mat-radio-inner-circle{
        background-color:#00254D;
    }
    .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
        background-color:transparent ;
    }
   .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple{
        background-color:transparent ;
    }
}
.neomp-action-dialog 
{
    .mat-radio-outer-circle{
    border-color:#00254D;
    }
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #00254D;
    }
    .mat-radio-button.mat-accent .mat-radio-inner-circle{
        background-color:#00254D;
    }
    .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
        background-color:transparent ;
    }
   .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple{
        background-color:transparent ;
    }

}