html body {
    .mat-snack-bar-container {
        background-color: $msg-color-primary-light;
        margin: 50px;
        padding: 4px 8px;
        min-height: 0px;

        > simple-snack-bar > div {
            margin: -8px -14px -8px 8px;

            > button > div {
                display: none;
            }
        }
    }

    .msg-snack-bar-default {
        background-color: $msg-color-secondary;

        simple-snack-bar span {
            color: $msg-color-secondary-special;
            font-weight: bold;
        }
    }

    .msg-snack-bar-success {
        background-color: $msg-color-signal-successful;

        simple-snack-bar span {
            color: $msg-color-secondary-special;
            font-weight: bold;
        }
    }

    .msg-snack-bar-checkdata {
        background-color: $msg-color-signal-warning;

        simple-snack-bar span {
            color: $msg-color-secondary;
            font-weight: bold;
        }
    }

    .msg-snack-bar-error {
        background-color: $msg-color-signal-error;

        simple-snack-bar span {
            color: $msg-color-secondary-special;
            font-weight: bold;
        }
    }
}
