.neomp-site-wrapper {
    .mat-checkbox {
        .mat-checkbox-inner-container {
            height: 19px;
            width: 19px;
        }
    }
    .mat-checkbox-checked  .mat-checkbox-checkmark-path{
        stroke:$msg-color-primary !important;
        stroke-width: 3.13333px;
    }
    .mat-checkbox-checked.mat-accent .mat-checkbox-background{
        background: $msg-color-transparent;
    }
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
         background-color: $msg-color-transparent;
        border: 1.5px solid;

    }

    .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element{
        background-color: $msg-color-primary-light;
    }

    .mat-checkbox.mat-checkbox-disabled {
        .mat-checkbox-label {
            color: #99a7b7;
        }
    }

    .mat-checkbox-frame{
        border-color: #00254D;
        border: 1.5px solid;
    
    }
   
   .mat-ripple-element{
        background-color:transparent !important;
    }
    .mat-checkbox-label{
        color:#00254D;
    }

}
.neomp-action-dialog 
{
    .mat-checkbox {
        .mat-checkbox-inner-container {
            height: 18px;
            width: 18px;
        }
    }
    .mat-checkbox-checked.mat-primary   .mat-checkbox-checkmark-path{
        stroke:$msg-color-bg;
        stroke-width: 3.13333px;
    }
    .mat-checkbox-checked.mat-primary .mat-checkbox-background{
        background-color: $msg-color-transparent;
    }
    .mat-checkbox-indeterminate .mat-checkbox-background, .mat-checkbox-checked.mat-primary  .mat-checkbox-background {
         background-color: $msg-color-primary;
        border: 1.5px solid;

    }
    .mat-checkbox-checked.mat-primary .mat-checkbox-background, .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background{
        background-color: $msg-color-primary;

    }
    .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element{
        background-color: $msg-color-primary-light;
    }

    .mat-checkbox.mat-checkbox-disabled {
        .mat-checkbox-label {
            color: $msg-color-fg;
        }
    }

    .mat-checkbox-frame{
        border-color: #00254D;
        border: 2px solid;
    
    }
   
   .mat-ripple-element{
        background-color:transparent !important;
    }
    .mat-checkbox-label{
        color:#00254D;
    }

}

// E5A remove black border color from all checkbox on LeadsTable ( Lead Management page )...
.all-checkbox * {
    color: white;
}