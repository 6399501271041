.neomp-site-wrapper {
    .breadcrumb {
        margin: 0;
        padding: 0;

        a {
            color: #00254D;
            text-decoration: none;
            font-size: $msg-headline1-font-size;
            text-transform: uppercase;
            font-weight: 700;
        }
        a.disabled-route {
            pointer-events: none;
            cursor: default;
        }

        li{
            list-style: none;
            float: left;
        }

        li + li:before{
            content: "|";
            padding: 0 0 0 5px;
            color: $msg-color-primary;
            font-size: $msg-headline2-font-size;
            font-weight: 700;
        }
    }
}
