$msg-color-fg: #000 !default;
$msg-color-bg: #fff !default;

$msg-color-primary: #00254D !default;
$msg-color-primary-light: #5E748C !default;
$msg-color-primary-special: #99A7B7 !default;


$msg-color-secondary: #757575 !default;
$msg-color-secondary-light: #C1C1C1 !default;
$msg-color-secondary-special: #ECECEC !default;

$msg-color-signal-error: #710206 !default;
$msg-color-signal-warning: #F9B200 !default;
$msg-color-signal-successful: #0BAF0F !default;

$msg-color-transparent: #00000000 !default;
