@import "../partials/_msg.mp.partial.variables";

.msg {
  &-debug {
    box-sizing: border-box;

    @if $debug ==false {
      display: none;
    }

    width: 100%;
    border: 1px solid $msg-color-secondary;
    background-color: $msg-color-secondary-light;

    * {
      color: $msg-color-fg;
      font-size: 0.8rem;
    }

    &-wrapper {
      padding: 5px 10px;
    }
  }

  &-hide {
    display: none;
  }

  &-show {
    display: inherit;
  }

  &-version {
    float: right;
  }
}

/* desktop and large tablets */
@media only screen and (min-width: ($msg-tablet-max +1)) {
  .msg {
    &-debug {
      border-color: #227a18;
      background: transparentize(#227a18, 0.5);
    }

    &-hide {
      &-l {
        display: none;
      }
    }

    &-show {
      &-l {
        display: inherit;
      }
    }
  }
}

/* tablet and large phones */
@media only screen and (max-width: $msg-tablet-max) {
  .msg {
    &-debug {
      border-color: #c15300;
      background: transparentize(#c15300, 0.5);
    }

    &-hide {
      &-m {
        display: none;
      }
    }

    &-show {
      &-m {
        display: inherit;
      }
    }
  }
}

/* phones */
@media only screen and (max-width: $msg-phone-max) {
  .msg {
    &-debug {
      border-color: #9b0c0e;
      background: transparentize(#9b0c0e, 0.5);
    }

    &-hide {
      &-s {
        display: none;
      }
    }

    &-show {
      &-s {
        display: inherit;
      }
    }
  }
}

.neo-custom-stepper * {
  font-family: $msg-font-family-content;
}

.neo-custom-stepper .mat-horizontal-stepper-header.mat-step-header {
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 5%;
  display: none;
}

.neo-custom-stepper .mat-horizontal-stepper-header.mat-step-header .mat-step-icon {
  background: transparent;
  border: 2px solid #02254d;
  padding: 5px;
  border-radius: unset;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.neo-custom-stepper .mat-horizontal-stepper-header.mat-step-header .mat-step-icon .mat-step-icon-content>span {
  font-size: 17px;
}

.neo-custom-stepper .mat-horizontal-stepper-header.mat-step-header .mat-step-icon-selected {
  background-color: #00254d;
}

.neo-custom-stepper .mat-horizontal-stepper-header.mat-step-header .mat-step-icon-selected span {
  color: #ffffff;
}

.neo-custom-stepper.mat-stepper-horizontal {
  background-color: transparent;
}

.neo-custom-stepper .mat-horizontal-stepper-header.mat-step-header .mat-step-label {
  text-align: center;
  padding-top: 10px;
  color: #02254d;
  padding-bottom: 40px;
}

.neo-custom-stepper .mat-horizontal-stepper-header.mat-step-header .mat-step-label .mat-step-text-label {
  font-size: 14px;
  color: #02254d;
}

.neo-custom-stepper .mat-horizontal-stepper-header-container .mat-stepper-horizontal-line {
  display: none;
}

.neo-custom-stepper .mat-horizontal-stepper-header-container {
  justify-content: center;
  display: none;
}

.neomp-site-wrapper .neo-border-none .mat-tab-labels {
  border: none;
}

.neo-no-underline .mat-form-field-appearance-legacy .mat-form-field-underline {
  width: unset;
}

.neo-house-hold mat-tab-header .mat-tab-label {
  height: 100px;
}

.neo-house-hold mat-tab-header .mat-tab-label:not(.mat-tab-label-active) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.neo-house-hold .mat-tab-labels>.mat-tab-label {
  background-color: #f2f2f2;
}

.neomp-site-wrapper .neo-house-hold .mat-tab-group.mat-primary .mat-tab-label-container .mat-tab-list .mat-ink-bar {
  top: 97%;
}

.neo-house-hold .mat-tab-labels {
  border-bottom: none;
}

.neo-house-hold .mat-tab-label:hover {
  background-color: #f2f2f2;
}

.neo-house-hold .mat-tab-label-active .mat-tab-label-content .title {
  color: #00254d;
  font-weight: 700;
}

.neo-house-hold.mat-tab-label .mat-tab-label-content .title {
  color: #00254d;
}

.neomp-site-wrapper .neo-house-hold .mat-tab-label-active:hover {
  background: transparent;
}

.neo-mat-overflow-unset .mat-slide-toggle-content {
  overflow: unset;
}

.neo-mat-custom-button.mat-button {
  background: #00254d;
  border-radius: 4px;
  line-height: 30px;
  padding: 0 20px;
}

.neo-mat-custom-button.mat-button span {
  background: #00254d;
  color: #ffffff;
}

.neo-float-right {
  float: right;
}

.neo-mat-reset-button.mat-button {
  background: #e3cccc;
  color: #710206;
  margin-left: 10px;
  border-radius: 4px;
  line-height: 30px;
  padding: 0 20px;
}

.neo-tab-custom .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none;
}

.neomp-action-dialog mat-tab-header .mat-tab-label:not(.mat-tab-label-active) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.neomp-action-dialog .mat-tab-label {
  background-color: #bfc8d2;
}

.neomp-action-dialog .mat-tab-group.mat-primary .mat-tab-label-container .mat-tab-list .mat-ink-bar {
  top: 97%;
}

.neomp-action-dialog .mat-tab-labels {
  border-bottom: none;
}

.neomp-action-dialog .mat-tab-label:hover {
  background-color: #bfc8d2;
}

.neomp-action-dialog .mat-tab-label-active .mat-tab-label-content .title {
  color: #00254d;
  font-weight: 700;
}

.neomp-action-dialog .mat-tab-label .mat-tab-label-content .title {
  color: #00254d;
}

.neomp-action-dialog .mat-tab-label-active:hover {
  background: transparent;
}

.neomp-action-dialog .mat-tab-label {
  opacity: 1;
  margin: 0px 5px 1px 0px;
  height: 34px;
  font-family: "NeoFont, Arial, sans-serif";
}

.neomp-action-dialog .mat-tab-labels {
  letter-spacing: 1.25px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.neomp-action-dialog .neo-tab-custom .mat-tab-label-active {
  background-color: #00254d;
}

.neomp-action-dialog .mat-tab-label-active .mat-tab-label-content {
  color: #fff;
}

.neomp-action-dialog .mat-tab-label-content {
  color: #00254d;
  text-transform: uppercase;
  font-weight: bold;
  font-family: $msg-font-family;
}

.neo-custom-option-font .mat-option-text {
  font-family: $msg-font-family-content;
}

.neo-custom-option-font .mat-option-text span {
  font-family: $msg-font-family-content;
}

.neomp-action-dialog .neo-tab-custom .mat-tab-group.mat-primary .mat-ink-bar {
  background-color: $msg-color-primary;
  top: 35px;
}

.neomp-action-dialog .mat-tab-group.mat-primary .mat-tab-label-cd-program-focused:not(.mat-tab-disabled) {
  background: #00254d;
}

div.mdc-layout-grid.neo-tab-custom div.mat-tab-label-container div.mat-tab-labels .mat-tab-label-active {
  background: #00254d;
}

.slide-toggle-text-width {
  padding-bottom: 30px;
}

.slide-toggle-text-width .mat-slide-toggle {
  white-space: break-spaces;
  line-height: 14px;
}

.slide-toggle-text-width .mat-slide-toggle .mat-slide-toggle-label .mat-slide-toggle-content {
  white-space: break-spaces;
}

.slide-toggle-max-width .mat-slide-toggle {
  max-width: none;
}

.mat-input-textarea-width {
  width: 300px;
}

.all-checkbox .mat-checkbox-layout {
  background-color: white;
}

.filter-checkbox .mat-checkbox-layout {
  padding-top: 7px;
}

.neomp-site-wrapper .all-checkbox .mat-checkbox-frame {
  border-color: transparent;
}

.neo-range .mat-date-range-input-start-wrapper {
  width: 62px;
}

.neo-range .mat-date-range-input-end-wrapper {
  width: 20px;
}

.neomp-site-wrapper .mat-form-field-appearance-fill .mat-form-field-flex,
.cdk-overlay-container .mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0px;
}

.neo-display-none .msg-mp-container .msg-mp-container-flex.msg-mp-container-header.msg-mp-container-headerbasic {
  display: none;
}

neomp-container.neo-padding-none div.msg-mp-container.msg-mp-container-frameless div.msg-mp-container-content {
  padding: 0px;
}

div.neo-crate-lead-btn button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary span.mat-button-wrapper {
  display: flex;
  width: 100%;
}

div.neo-crate-lead-btn button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary:disabled {
  background-color: transparent;
  border: 1px solid #bfc8d2;
  border-radius: 4px;
}

div.neo-crate-lead-btn button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary.mat-button-disabled span.mat-button-wrapper div.btn-child span {
  color: #bfc8d2;
}

.mat-header-cell.cdk-header-cell.column-w-20.pr-10.cdk-column-action.mat-column-action {
  padding-right: 10px;
}

.lead-create-wrapper .neo-form-width .mat-form-field-underline {
  display: none;
}

.fc-event.fc-event-draggable.fc-timegrid-event.fc-v-event
.fc-event-main b{
  font-size: 11px ;
}