.neomp-site-wrapper {
  table {
    width: 100%;
    @include browser(Edge) {
      table-layout: fixed;
    }

    tbody {
      tr {
        cursor: pointer;
      }
    }

    div.mat-form-field-infix {
      width: 100%;
    }
  }

  .mat {
    &-cell:first-of-type,
    &-footer-cell:first-of-type,
    &-header-cell:first-of-type {
      padding-left: 8px;
    }

    &-cell:last-of-type,
    &-footer-cell:last-of-type,
    &-header-cell:last-of-type {
      padding-right: unset;
    }

    &-cell {
      // font-size: $msg-font-base-size;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-header-row {
      &.task-product-table-header {
        background-color: #eb6a31;
      }
      // height: $mat-table-row-height;
      // background-color: $msg-color-primary-special;
      background: #00254d;
      height: 45px;

      th {
        border-bottom: 1px solid $msg-color-bg;
        padding-left: 3px;
      }

      .mat-column-filter {
        &-active {
          background: $msg-color-primary;
        }

        @include table-filter-column;
        border-left: solid 2px $msg-color-bg;

        &:first-of-type {
          border-left: none;
        }
      }
            &.task-product-table-header {
                background-color: #eb6a31;
            }
    }
  }

  .mat-header-cell,
  .mat-sort-header-button {
    color: $msg-color-bg;
  }

  .mat-sort-header-stem,
  .mat-sort-header-pointer-left,
  .mat-sort-header-pointer-right,
  .mat-sort-header-pointer-middle {
    color: white;
  }

  .mat-header-cell .mat-sort-header-container > button {
    &:after {
      font-family: "Material Icons";
      content: "arrow_drop_down";
      font-size: 2em;
      visibility: hidden;
    }
  }

  .mat-header-cell .mat-sort-header-sorted > button {
    color: $msg-color-bg;

    &:after {
      font-family: "Material Icons";
      font-size: 2em;
      visibility: visible;
    }
  }

  .mat-header-cell[aria-sort="descending"] .mat-sort-header-sorted > button {
    &:after {
      content: "arrow_drop_down";
    }
  }

  .mat-header-cell[aria-sort="ascending"] .mat-sort-header-sorted > button {
    &:after {
      content: "arrow_drop_up";
    }
  }

  // .mat-header-cell > div > div {
  //     display: none;
  // }

  .div-status {
    width: 15px;
    height: 100%;
    padding-right: 10px;
  }
  .neo-meine-kunden {
    width: 15px;
    height: 100%;
    padding-right: 0px;
  }

  .completed-status {
    background: url("/assets/images/completed.svg") 50% 50% no-repeat;
    background-size: 20px;
  }
  .active-status {
    background: url("/assets/images/sand-clock-1.svg") 50% 50% no-repeat;
    background-size: 15px;
  }

  .warning-status {
    background: url("/assets/images/sand-clock-2.svg") 50% 50% no-repeat;
    background-size: 15px;
  }

  .servicecenter {
    background: url("/assets/images/warning-sign.svg") 50% 50% no-repeat;
    background-size: 20px;
  }

  .inactive-status {
    background: url("/assets/images/sand-clock-3.svg") 50% 50% no-repeat;
    background-size: 15px;
  }

  .highligt-status {
    background-color: $msg-color-primary;
  }

  .neomp-table-row-filter {
    background: $msg-color-secondary-special;

    th {
      border-bottom: 1px solid $msg-color-bg;
      padding-bottom: 6px;
    }

    .mat-form-field {
      width: calc(100% - 5px);

      &-wrapper {
        height: $mat-table-row-height;
        padding-bottom: 5px;
      }

      &-underline {
        bottom: 5px;
      }

      &-subscript-wrapper {
        bottom: 0;
      }

      &-infix {
        border-top: 0.544em solid transparent;
      }
      &-header-row {
        // height: $mat-table-row-height;
        // background-color: $msg-color-primary-special;
        background: #00254d;
        height: 45px;

        th {
          border-bottom: 1px solid $msg-color-bg;
          padding-left: 3px;
        }

        .mat-column-filter {
          &-active {
            background: $msg-color-primary;
          }

          @include table-filter-column;
          border-left: solid 2px $msg-color-bg;

          &:first-of-type {
            border-left: none;
          }
        }
      }
    }

    .mat-form-field.mat-focused .mat-form-field-ripple {
      background-color: $msg-color-primary-special;
    }

    &-hide {
      display: none;
    }

    .mat-column-filterRemove {
      @include table-filter-column;
      border-left: solid 2px $msg-color-bg;
    }
  }

  .container-table-row,
  .mat-header-row {
    .mat-column {
      &-status {
        padding: 2px 0px 2px 0px;
        width: 20px;
      }
    }
  }

  .container-table-row {
    .mat-column {
      &-filter {
        @include table-filter-column;
        background: $msg-color-secondary-special;
        box-shadow: inset 0px 2px 0px 0px $msg-color-bg,
          inset 2px 0px 0px 0px $msg-color-bg,
          inset 0px -2px 0px 0px $msg-color-bg;
        &.has-messages {
          background-color: #70c9e6;
        }
        &-actions {
          background: #eb6a31;
        }
      }

      &-send {
        padding-right: unset;
        width: $mat-table-icon-column-width;
        height: 34px;
        background: #00254d;
        box-shadow: inset 0px 2px 0px 0px $msg-color-bg,
          inset 2px 0px 0px 0px $msg-color-bg,
          inset 0px -2px 0px 0px $msg-color-bg;

        &-active {
          background: #00254d;
        }
      }
    }
    &.bold {
      font-weight: 900;
    }

    // height: $mat-table-row-height;
    height: 45px;

    td {
      height: $mat-table-row-height;
      padding: 0px 2px;
      box-shadow: inset 0px 2px 0px 0px $msg-color-bg,
        inset 0px -2px 0px 0px $msg-color-bg;
      @include table-border-bottom;
    }

    &:last-of-type {
      .mat-column-status {
        padding: 2px 0px 0px 0px;
        width: 10px;
      }
      height: $mat-table-row-height td {
        border-bottom-width: 0;
      }
    }
  }
  .neomp-table-row-selected:hover,
  .neomp-table-row-selected:active,
  .container-table-row-selected .mat-column-status {
    background: #ececec;
  }

  .neomp-table-row-selected:hover {
    td {
      color: $msg-color-primary;
    }
  }

  .neomp-table-row-selected:hover,
  .neomp-table-row-selected:active,
  .container-table-row-selected {
    td.has-messages {
      background: #70c9e6;
    }
    td:not(.has-messages):not(.mat-column-send):not(.mat-column-status):not(.mat-column-filter-action) {
      background: $msg-color-secondary-special;
    }

    .mat-column-filter {
      @include table-filter-column;
      background: #00254d;
      box-shadow: inset 0px 2px 0px 0px $msg-color-bg,
        inset 2px 0px 0px 0px $msg-color-bg,
        inset 0px -2px 0px 0px $msg-color-bg;

      .neomp-table-column-filter-element {
        background: #00254d;
      }
    }
  }

  .mat-cell {
    .neomp-table-column-filter-element {
      width: $mat-table-icon-column-width;
      @include table-column-filter-element;
    }
  }

  .mat-header-cell {
    .neomp-table-column-filter {
      &-element {
        @include table-column-filter-element;
      }
    }
  }

  table {
    .icon-hide {
      display: none;
    }

    .neo-range .mat-form-field-infix {
      padding-top: 0.85em;
    }
  }
}

.mat-sort-header-content {
  color: white;
}
