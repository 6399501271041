.neomp-site-wrapper {
  .mat-slide-toggle-bar {
    background-color: grey;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: grey;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $msg-color-primary;
  }
  .mat-slide-toggle-thumb {
    background-color: #70c9e6;
  }
  .mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: $msg-color-primary-light;
  }
  .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
    background-color: $msg-color-primary-light;
  }
  .mat-slide-toggle.mat-disabled {
    opacity: 1;
  }
  .mat-slide-toggle.mat-disabled > label > div {
    opacity: 0.38;
  }
}
