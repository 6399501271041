@import "msg.mp.layout.material";
@import "msg.mp.layout.typo";
@import "msg.mp.layout.helper";

* {
    box-sizing: content-box;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100vh;
    background: $msg-color-secondary-special;
}

