body {
    .neomp-site-wrapper {
        .neomp-slideRangeFilter-DatePicker {
            .mat-form-field {
                width: 180px;

                .mat-form-field-wrapper {
                    .mat-form-field-flex {
                        padding-left: 0px;
                        .mat-form-field-infix {
                            border-top: 0px;
                            padding-bottom: 0.35em;
                        }
                    }
                }
            }
        }
    }

    .cdk-overlay-container {

        .mat-calendar-table-header-divider {
            display: none;
        }

        .mat-calendar {
            height: fit-content;
            width: 286px;

            .datePickerTopHeader {
                background-color: #D9DADB;
                display: flex;
                justify-content: space-between;
                color: $msg-color-bg;
                padding: 5px 10px 4px 12px;

                .topHeaderTable {
                    padding: 5px;
                    max-width: 65%;
                }

                .yearLabel {
                    font-size: 15px;;
                    font-family: $msg-font-family;
                    font-weight: bold;
                    text-align: left;
                    display: inline;
                    color:#00254D;
                }

                .calendarCheckIcon {
                    color: $msg-color-bg;
                    font-size: 41px;
                }
            }

            .neomp-date-picker-header {
                display: flex;
                background: #fff;
                align-items: center;
                font-weight: bold;
                padding-left: 21px;
                padding-right: 20px;
                padding-top: 7px;
                font-size: $msg-font-base-size;
                font-family: $msg-font-family;

                .neomp-date-picker-header-label {
                    border: 0px;
                    flex: 1;
                    height: auto;
                    font-weight: 600;
                    text-align: center;
                    color: $msg-color-secondary;
                    font-family: $msg-font-family;
                    font-size: $msg-font-base-size;
                }

                .calendarArrow {
                    color: $msg-color-secondary;
                    font-size: 20px;
                }
            }

            .mat-calendar-content {
                padding-left: 8px;
                padding-right: 8px;
                font-weight: bold;

                .mat-calendar-body {
                    height: 145px;
                    width: 159px;

                    tr[aria-hidden="true"] {
                        display: none;
                    }

                    .mat-calendar-body-label {
                        opacity: 0;
                    }
                }
            }

            .mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
                border-radius: 100% 0 0 100%;
                background-color: #70C9E6;
            }

            .mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
                border-radius: 0 100% 100% 0;
                background-color: #70C9E6;
            }

            .mat-calendar-body-selected {
                background-color: #70C9E6;
                color: $msg-color-bg;
            }

            .mat-calendar-cell-semi-selected {
                background-color: #70C9E6;

                .mat-calendar-body-semi-selected {
                    background-color: #70C9E6;
                    color: $msg-color-bg;
                    border-radius: 0;
                }
            }

            .mat-calendar-cell-over > .mat-calendar-body-cell-content {
                border: #70C9E6 1px solid;
            }

            :not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected), .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected) {
                background-color: $msg-color-primary-special;
                color: $msg-color-bg;
            }
        }

        .mat-datepicker-content {
            .ng-star-inserted {
                color: #00254D;
                font-size: $msg-font-base-size;
                font-family: $msg-font-family;
                font-weight: bold;
            }
        }

        .mat-calendar-body-cell-content {
            color: #000000DD;
        }
    }

    .footer {
        margin-bottom: 2px;
        background-color: #fff;
        border-top: 1px solid #0000001c;    
        direction: rtl;

        .footerCancelButton {
            border: none;
            background-color: #fff;
            color: $msg-color-primary;
            font-weight: bold;
            margin-bottom: 8px;
            margin-right: 9px;
            margin-top: 8px;
        }
    }
}
@media only screen and (max-width: $msg-phone-max) {
    body {
        .neomp-site-wrapper {
            .neomp-slideRangeFilter-DatePicker {
                .mat-form-field {
                    width: 175px;
                 } 
                }
            } 
          }
}
