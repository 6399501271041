.neomp-site-wrapper,
.cdk-overlay-container {
  .neo-content-wrapper,
  .cdk-global-overlay-wrapper {
    .mat-form-field {
      width: 95%;

      .mat-form-field-underline,
      .mat-form-field-ripple {
        background-color: $msg-color-secondary-light;
        height: 1px;
      }

      .mat-form-field-required-marker {
        color: $msg-color-secondary-light;
      }

      .mat-input-element {
        color: $msg-color-fg;
        font-family: Calibri !important;
        font-size: 14px;
      }
    }

    .neomp-paginator-go-to-field {
      margin: 6px 4px 0 4px;
      width: 56px;
    }
    
    .mat-paginator-page-size-select {
      margin: 6px 4px 0 4px;
      width: 56px;
  }

    .mat-form-field.mat-form-field-disabled {
      * {
        color: $msg-color-secondary-light;
      }

      .mat-form-field-underline,
      .mat-form-field-ripple,
      .mat-form-field-required-marker,
      .mat-form-field-label,
      .mat-input-element {
        color: $msg-color-secondary-light;
        background-image: none;

        .mat-form-field-required-marker {
          color: $msg-color-secondary-light;
        }
      }
    }

    .mat-form-field.mat-focused {
      .mat-form-field-required-marker,
      .mat-form-field-label {
        color: $msg-color-primary-light;

        .mat-form-field-required-marker {
          color: $msg-color-primary-light;
        }
      }

      .mat-form-field-underline,
      .mat-form-field-ripple {
        background-color: $msg-color-primary;
      }
    }

    .mat-form-field.mat-form-field-invalid {
      * {
        color: $msg-color-signal-error;
        caret-color: $msg-color-signal-error;
      }

      .mat-form-field-label,
      .mat-select-arrow {
        color: $msg-color-signal-error;

        .mat-form-field-required-marker {
          color: $msg-color-signal-error;
        }
      }

      .mat-form-field-underline {
        background-color: $msg-color-signal-error;
      }
    }

    .mat-form-field.mat-form-field-warning {
      .mat-input-element,
      .mat-form-field-label,
      .mat-form-field-ripple,
      .mat-form-field-underline {
        background-color: $msg-color-signal-warning;
      }
    }

    .mat-form-field.mat-form-field-valid {
      .mat-input-element,
      .mat-form-field-label,
      .mat-form-field-ripple,
      .mat-form-field-underline {
        background-color: $msg-color-signal-successful;
      }
    }
  }
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: $msg-color-transparent;
  }

  .mat-paginator-range-actions .mat-paginator-range-label {
    margin: 0 10px 0 10px;
  }

  .mat-paginator-range-actions .mat-icon-button {
    width: 28px;
  }


}

body {
  .mat-primary {
    .mat-pseudo-checkbox-checked {
      background: $msg-color-primary;
    }
  }
}
body {
  .multiple-select {
    .mat-pseudo-checkbox-checked {
      background: $msg-color-primary;
    }
  }
}

.leads-edit {
  .mat-form-field-wrapper {
    padding-bottom: 1.1rem;
  }
}

.mat-form-field
  .mat-form-field-wrapper
  .mat-form-field-flex
  .mat-form-field-infix
  .mat-form-field-label-wrapper
  .mat-form-field-label
  span {
  color: rgba(0,0,0,.54);
}

.mat-form-field.mat-focused
  .mat-form-field-wrapper
  .mat-form-field-flex
  .mat-form-field-infix
  .mat-form-field-label-wrapper
  .mat-form-field-label
  span {
  color: #3f51b5;
}

.neo-range.mat-form-field.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: #c1c1c1;
}

  .neo-range-input::placeholder {
    color: rgba(0,0,0,.54);
    font-weight: 400;
}

