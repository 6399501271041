@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&subset=latin-ext");
//import the icon set
//Icon Cheat Sheet https://cdn.materialdesignicons.com/2.8.94/
@import "../../../node_modules/@mdi/font/css/materialdesignicons.min.css";

@font-face {
  font-family: "NeoFont";
  // src: url("/assets/fonts/univiapro-regular-webfont.woff2") format("woff2"),
  // url("/assets/fonts/univiapro-regular-webfont.woff") format("woff");
  src: url("/assets/fonts/OpenSans-Regular.woff2") format("woff2"),
  url("/assets/fonts/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NeoFont";
  // src: url("/assets/fonts/univiapro-light-webfont.woff2") format("woff2"),
  // url("/assets/fonts/univiapro-light-webfont.woff") format("woff");
  src: url("/assets/fonts/OpenSans-Light.woff2") format("woff2"),
  url("/assets/fonts/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: "NeoFont";
  // src: url("/assets/fonts/univiapro-bold-webfont.woff2") format("woff2"),
  // url("/assets/fonts/univiapro-bold-webfont.woff") format("woff");
  src: url("/assets/fonts/OpenSans-SemiBold.woff2") format("woff2"),
  url("/assets/fonts/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NeoFont";
  // src: url("/assets/fonts/univiapro-bold-webfont.woff2") format("woff2"),
  // url("/assets/fonts/univiapro-bold-webfont.woff") format("woff");
  src: url("/assets/fonts/OpenSans-Bold.woff2") format("woff2"),
  url("/assets/fonts/OpenSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

* {
    font-size: $msg-font-base-size;
    font-family: $msg-font-family;
    color: $msg-color-fg;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 10px 0;
}

h1 {
    color: #00254D;
    font-weight: 700;
    font-size: $msg-headline1-font-size;
}

h2 {
    color: $msg-headline2-color;
    font-weight: 700;
    font-size: $msg-headline2-font-size;
}

h3 {
    color: $msg-headline3-color;
    font-weight: 700;
    font-size: $msg-headline3-font-size
}
.msg-data-label {
    color: $msg-color-primary;
}
td{
  font-family: Calibri;
}
input.mat-input-element{
  font-family: Calibri !important;
  font-size: 14px;
}
.policy-list{
  font-family: Calibri;
  font-size: 14px;
}
.data-label{
  font-family: Calibri;
  font-size: 14px;
}
.mat-checkbox-label{
  font-family: Calibri;
  font-size: 14px;
}
.font-family{
  font-family: Calibri;
  font-size: 14px;
}
.mat-select-placeholder{
  font-family: Calibri;
}
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-empty.mat-form-field-label {
  font-family: Calibri;
  font-size: 14px;
}
textarea{
  font-family: Calibri !important;
  font-size: 14px !important;
}