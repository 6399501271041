/* You can add global styles to this file, and also import other style files */
@import "partials/msg.mp.partial.main";
@import "layout/msg.mp.layout.main";
@import "platform/msg.mp.platform.main";
@import "material/msg.mp.material.main";
@import "material/msg.mp.material.dialog";
.overflow-hidden > .mat-drawer-inner-container{
    overflow: hidden;
    width: 260px;
}
.mat-paginator-page-size-label{
    width: 100%
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 18px !important;
}
.neo-white p {
    color: white; 
    strong{
       color: white; 
    } 
}
.neo-orange p {
    color: #eb6a31; 
    strong{
       color: #eb6a31; 
    } 
}
.neo-neoColor p {
    color: #00254d; 
    strong{
       color: #00254d; 
    }  
}
.mat-select-in mat-select {
    font-size: 14px;
    .mat-select-trigger{
      .mat-select-value{
        .mat-select-value-text{
          .mat-select-min-line{
            z-index: 999;
            position: absolute;
          }
        }
      }
      .mat-select-arrow-wrapper{
        .mat-select-arrow{
            float: right;
        }
    }
    }
  }