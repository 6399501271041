.neomp-site-wrapper {
    mat-tab-header {
        border-bottom: none;
    }

    .neo-lead-tab
    .mat-tab-header {
        display: none;
    }

    .mat-tab-label {
        background-color: #BFC8D2;
        opacity: 1;
        margin: 0px 5px 1px 0px;
        height: 34px;
    }

    .neo-label .mat-tab-label{
        min-width: 120px ;
        padding: 0px 8px;
        height: 40px;
    }

    .mat-tab-labels {
        letter-spacing: 1.25px;
        border-bottom: 2px solid rgba(0, 0, 0, .12);
    }

    .mat-tab-label-active {
        background-color: #00254D;
     
    }
    .mat-tab-label-active .mat-tab-label-content{
        color: #fff;
    }

    .mat-tab-label-content {
        color: #00254D;
        text-transform: uppercase;
        font-weight: bold;
    }

    .mat-tab-group.mat-primary .mat-ink-bar {
        background-color: $msg-color-primary;
        top: 38px;
    }

    @include browser(Edge) {
        .mat-tab-body.mat-tab-body-active {
            overflow-x: visible;
            overflow-y: visible;
        }
        .mat-tab-body-content {
            overflow: visible;
        }
    }
}


