.neomp-dialog-padding-none {
  .mat-dialog-container {
    padding: 0px;
    border-radius: inherit;
    overflow: inherit;

    .mat-dialog-title {
      &.neomp-dialog-title {
        margin: 0px;
        padding: 8px 12px 8px 12px;
        color: $msg-color-bg;
        background-color: $msg-color-primary;
        font: normal $msg-headline3-font-size $msg-font-family;
      }
    }

    .mat-dialog-content {
      //padding: 16px;
      padding: 16px 30px 16px 30px;
      &.neomp-dialog-content {
        margin: 8px;
        font: normal $msg-font-size-regular $msg-font-family;

        // .neomp-dialog-content-file-upload {
          // Fix required, see issue neo-broker #121
        // }
      }
    }

    .mat-dialog-actions {
      &.neomp-dialog-actions {
        padding-left: 8px;
        padding-right: 8px;
        justify-content: flex-end;
        background-color: $msg-color-bg;

        .neomp-dialog-action-button {
          padding: 0px 16px 0px 16px;
          margin: 0px 4px 0px 4px;

          .mat-button-wrapper {
            font: $msg-font-size-large $msg-font-family;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }
      .cancel-action {
        background-color: #e3cccc;
      }
      .cancel-action .mat-button-wrapper {
        color: #710206;
      }
      .save-action {
        background-color: #bfc8d2;
      }
      .save-action .mat-button-wrapper {
        color: #02254d;
      }
    }
  }
  .neo-slide-color.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #efefef;
  }
  .neo-slide-color.mat-slide-toggle .mat-slide-toggle-bar {
    background-color: #efefef;
  }
  .neo-slide-color.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $msg-color-primary;
  }
  .neo-slide-color.mat-slide-toggle .mat-slide-toggle-thumb {
    background-color: #70c9e6;
  }
  .neo-no-padding .mat-checkbox-layout {
    white-space: unset;
  }
  .neomp-action-dialog .mat-checkbox-label {
    color: #000000de;
    font-size: 12px;
    line-height: 17px;
  }
  .neo-font-size-14 .mat-select-value-text > span {
    font-size: 14px;
  }
  .mat-select-value {
    color: #000000de;
  }
  .mat-select {
    font-family: $msg-font-family-content;
  }
  .neo-font-size-14 .mat-form-field-hide-placeholder .mat-select-placeholder {
    color: #000000de;
    font-size: 14px;
  }
  .mat-form-field-infix .mat-input-element.mat-form-field-autofill-control {
    color: #000000de;
    font-size: 14px;
  }
  .font-family {
    font-family: $msg-font-family;
  }
  * {
    font-family: $msg-font-family-content;
    color: #000000de;
  }
  h2 {
    color: #00254d;
    font-family: $msg-font-family;
  }
}

@media only screen and (max-width: $msg-phone-max) {
  .neomp-dialog-padding-none
    .mat-dialog-container
    .mat-dialog-actions.neomp-dialog-actions {
    padding-left: 1px;
    padding-right: 3px;
  }
}

.mat-sort-header-content {
  color: white;
}

.uppy-DashboardContent-back
// .uppy-DashboardContent-save
{
  color: $msg-color-signal-error;
  background: transparent;
}

.uppy-Dashboard-inner {
  background-color: white;
}

.uppy-DashboardContent-back:hover
// .uppy-DashboardContent-save:hover
{
  color: $msg-color-signal-error;
}

.uppy-DashboardContent-addMoreCaption {
  color: #2275d7;
}

.uppy-c-icon * {
  color: #2275d7;
}

.uppy-c-btn-primary {
  color: white;
}

.uppy-ProviderBrowser-userLogout {
  color: #2275d7;
}

.uppy-Provider-authTitle {
  color: #757575;
  span {
    color: #757575;
    font-size: 20px;
  }
}
