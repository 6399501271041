@import "msg.mp.partial.variables";
@import "msg.mp.partial.colors";
@import "msg.mp.partial.functions";
@import "msg.mp.partial.mixins";

tr, tr * {
    user-select: none;
}

td {
    user-select: text;
}

body {
    user-select: none;
}