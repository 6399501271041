.msg-helper {
    &-justify-items {
        &-end {
            justify-items: end;
        }

        &-start {
            justify-items: start;
        }

        &-center {
            justify-items: center;
        }
    }

    &-text {
        &-end {
            text-align: right;
        }

        &-start {
            text-align: left;
        }

        &-center {
            text-align: center;
        }
    }

    &-position {
        &-relativ {
            position: relative;
        }
    }

    &-display {
        &-flex {
            display: flex;
        }

        &-none {
            display: none;
        }

        &-inline-block {
            display: inline-block;
        }
    }

    &-overflow {
        &-hidden {
            overflow: hidden;
        }
    }

    &-float {
        &-left {
            float: left;
        }

        &-right {
            float: right;
        }
    }

    &-padding {
        &-right {
            &-5 {
                padding-right: 5px;
            }
        }

        &-left {
            &-5 {
                padding-left: 5px;
            }
        }

        &-none {
            padding: 0;
        }

        &-10 {
            padding: 10px;
        }
        &-5 {
            padding: 5px;
        }
    }

    &-grid {
        &-gap-none {
            grid-gap: 0;
        }

        &-gap-10 {
            grid-gap: 10px;
        }

        &-gap-5 {
            grid-gap: 5px;
        }

        &-gap-horizontal {
            grid-row-gap: 0px;
        }
    }

    &-margin {
        &-none {
            margin: 0;
        }

        &-vertical {
            &-auto {
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        &-horizontal {
            &-auto {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &-right {
            &-10 {
                margin-right: 10px;
            }
            &-25 {
                margin-right: 25px;
            }
        }

        &-top {
            &-10 {
                margin-top: 10px;
            }

            &-15 {
                margin-top: 15px;
            }
        }

        &-side {
            &-5 {
                margin: 0 5px;
            }
            &-10 {
                margin: 0 10px;
            }

            &-25 {
                margin: 0 25px;
            }
        }

        &-left {
            &-10 {
                margin-left: 10px;
            }
            &-20 {
                margin-left: 20px;
            }
            &-auto {
                margin-left: auto;
            }
        }

        &-bottom {
            &-10 {
                margin-bottom: 10px;
            }
        }
    }

    &-spacing {
        &-none {
            margin: 0;
            padding: 0;
        }
    }

    &-height-100 {
        height: 100%;
    }

    &-width-100 {
        width: 100% !important;
    }

    &-user-select-auto {
        user-select: auto !important;
    }

    &-icon-font-size {
        font-size: $msg-font-size-icons;
    }
    &-icon-font-size-max{
        font-size: $msg-font-size-icons-max;
        color: $msg-color-secondary-light;
    }
    &-icon-font-size-min{
        font-size: $msg-font-size-icons-min;
        color: $msg-color-secondary-light;
        align-self: center;
    }
}
