@import "../partials/_msg.mp.partial.variables";

/* tablet and large phones */
@media only screen and (max-width: $msg-tablet-max) {
    //buttons
    .neomp-site-wrapper {
        .msg-btn {
            &-row {
                a, button {
                    margin: 0 $msg-button-row-space $msg-button-row-space 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .tablet-grid.grid-columns-custom{
            -moz-column-count: 4;
            -moz-column-gap: 20px;
            -webkit-column-count: 4;
            -webkit-column-gap: 20px;
            column-count: 4;
            column-gap: 20px;
            list-style: none;
            padding-left: 0;
            display: flex;
        }
    }
    // .padding-top-50-small-size{
    //     padding-top: 50px;
    // }

}
