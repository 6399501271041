@import "msg.mp.partial.colors";

// config
$debug: false !default;

// typo
$msg-font-family: NeoFont, Arial, sans-serif !default;
$msg-font-base-size: 12px !default;
$msg-font-family-content: 'Calibri' !default;

$msg-headline1-font-size: 1.667rem !default;
$msg-headline2-font-size: 1.5rem !default;
$msg-headline3-font-size: 1.5rem !default;
$msg-headline1-color: $msg-color-primary;
$msg-headline2-color: $msg-color-primary;
$msg-headline3-color: $msg-color-primary-special;

$msg-font-size-regular: 1rem !default;
$msg-font-size-small: 0.833rem !default;
$msg-font-size-large: 1.167rem !default;
$msg-font-size-icons: 1.52rem !default;
$msg-font-size-icons-max: 8rem !default;
$msg-font-size-icons-min: 4rem !default;

$msg-border-radius: 2px;

$msg-base-radius: 4px;
$msg-border-radius-xs: $msg-base-radius / 2 !default;
$msg-border-radius-s: $msg-base-radius !default;
$msg-border-radius-m: $msg-base-radius * 2 !default;
$msg-border-radius-l: $msg-base-radius * 3 !default;
$msg-border-radius-xl: $msg-base-radius * 6 !default;
$msg-border-radius-xxl: $msg-base-radius * 9 !default;

// responsive
$msg-desktop-max: 1200px;
$msg-tablet-max: 959px;
$msg-phone-max: 599px;
$msg-logo-width-l: 100px;
$msg-logo-width-m: 60px;
$msg-logo-width-s: 40px;

// override material grid
$mdc-layout-grid-breakpoints: (
    desktop: $msg-tablet-max + 1,
    tablet: $msg-phone-max + 1,
    phone: 0px
);

$mdc-layout-grid-columns: (
    desktop: 12,
    tablet: 12,
    phone: 12
);
//tables
$mat-table-row-height: 30px;
$mat-table-icon-column-width: 30px;

//buttons
$msg-button-radius: $msg-border-radius;
$mat-button-line-height: 30px;
$mat-button-padding: 20px;
$msg-button-row-space: 10px;
$msg-button-font-weight: normal;
$msg-button-text-transform: uppercase;
$msg-button-primary-bg: $msg-color-primary;
$msg-button-primary-color: $msg-color-bg;

//header
$msg-header-height: 40px;

// square box
$msg-square-width: 48px !default;
$msg-square-min-height: 48px !default;
$msg-square-padding: 8px !default;