
.msg-loadingstatus {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;

    .mdi:before {
        position: relative;
        top: 3px;
        right: 6px;
        font-size: $msg-font-size-icons;
    }
}


.msg-nodata div {
    color: $msg-color-secondary-light;
}

.msg-nodata div span {
    color: inherit;
}

.msg-loadingerror div {
    color: $msg-color-signal-error;
}

.msg-loadingerror div span {
    color: inherit;
}


.msg-loading {
    height: 60px;
    width: 100%;
}

.msg-loading-animation {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.msg-loading-animation div {
    display: inline-block;
    margin: 2px;
    width: 11px;
    border-radius: $msg-border-radius;
    background: $msg-color-secondary-light;
    animation: msg-loading-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.msg-loading-animation div:nth-child(1) {
    left: 0px;
    animation-delay: -0.24s;
}

.msg-loading-animation div:nth-child(2) {
    left: 39px;
    animation-delay: -0.12s;
}

.msg-loading-animation div:nth-child(3) {
    left: 78px;
    animation-delay: 0;
}

@keyframes msg-loading-animation {
    0% {
        top: 6px;
        height: 20px;
    }
    50%, 100% {
        top: 19px;
        height: 8px;
    }
}
