@import "../partials/_msg.mp.partial.variables";

/* desktop and large tablets */
@media only screen and (min-width: ($msg-tablet-max +1)) {
    //buttons
    .neomp-site-wrapper {
        .msg-btn {
            &-row {
                a, button {
                    margin: 0 $msg-button-row-space $msg-button-row-space 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .padding-top-2 {
        padding-top: 2%;
    }
}


