//Component https://material.angular.io/components/sidenav/overview
.neomp-site-wrapper {
    .mat-drawer-container {
        background: #f2f2f2;
    }

    .mat-drawer-side {
        border-right: none;
    }
}
