@import "../partials/_msg.mp.partial.variables";
/* phones */
@media only screen and (max-width: $msg-phone-max) {
    //buttons
    .neomp-site-wrapper {
        .msg-btn {
            &-row {
                a, button {
                    margin: $msg-button-row-space 0 0 0;

                    &:first-child {
                        margin: 0;
                    }
                }
            }
        }
        .mat-raised-button {
            width: 100%;
        }
    }
    .padding-top-50-small-size{
        padding-top: 50px;
    }
    .neo-color .grid-columns > .display-table-caption{
        display: inline-block;
    }
    .tablet-grid.phone-grid.grid-columns-custom{
    
        display: contents;
    }
    .display-contents{
        display: contents;
    }
    .section-padding{
        padding: 0px !important;
    }
    .radio-button-custom{
        margin-left:0px !important;
    }
    .align-center.align-center.min-height-250{
        justify-content: flex-start;
    }
    .neo-custom-stepper .mat-horizontal-stepper-header-container{
        display: block;
    }
}
